@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

// shimano color pallete
$shimano-color: (
  50: #e0f3fa,
  100: #b3e1f4,
  200: #80cdec,
  300: #4db8e4,
  400: #26a9df,
  500: #009ad9,
  600: #0092d5,
  700: #0088cf,
  800: #007eca,
  900: #006cc0,
  A100: #e9f4ff,
  A200: #b6dbff,
  A400: #83c2ff,
  A700: #69b5ff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

// primary, accent, warn pallete
$manual-ui-primary: mat.define-palette($shimano-color);
$manual-ui-accent: mat.define-palette($shimano-color, A200, A100, A400);
$manual-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$manual-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $manual-ui-primary,
      accent: $manual-ui-accent,
      warn: $manual-ui-warn,
    ),
  )
);

// foreground, background pallete
/*
$app-light-theme: map-merge(
  $app-light-theme,
  (
    color:
      map-merge(
        map-get($app-light-theme, color),
        (
          foreground:
            map-merge(
              $mat-light-theme-foreground,
              (
                text: $black-87-opacity,
              )
            ),
        )
      ),
  )
);

$app-light-theme: map-merge(
  $app-light-theme,
  (
    color:
      map-merge(
        map-get($app-light-theme, color),
        (
          background:
            map-merge(
              $mat-light-theme-background,
              (
                hover: yellow,
              )
            ),
        )
      ),
  )
);
*/

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-legacy-component-themes($manual-ui-theme);
@include mat.all-component-themes($manual-ui-theme);
