/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

.amplify-tabs {
  display: none;
}

html {
  scroll-behavior: auto !important;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  font-family: "Roboto", Verdana, sans-serif;
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}
